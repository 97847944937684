import React, {useState, useEffect, memo} from 'react';

import {useNavigate, useParams} from "react-router-dom";

import Input from "../../Components/Input";
import {Dropdown, DropdownItem} from "../../Components/Dropdown";
import ProjectFavouriteButton from './ProjectFavouriteButton';

const ProjectTransactionsMenu = ({onFiltersChange, onExportToExcel}) => {
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();
  const {projectId} = useParams();


  useEffect(() => {
    onFiltersChange({searchValue});
  }, [searchValue]);

  return (
    <div className="row d-flex justify-content-between">
      <div className="col-12 col-lg-4">
        <Input
          value={searchValue}
          placeholder={'Пошук'}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <div className="col-12 col-lg-2 d-flex justify-content-end">
        <ProjectFavouriteButton />
        <Dropdown text={`\u2630`}>
          <DropdownItem text='Історія' onClick={() => navigate(`/project/${projectId}/history`)}/>
          <DropdownItem text='Налаштування' onClick={() => navigate(`/project/${projectId}/edit`)}/>
          <DropdownItem text='Експорт в Excel' onClick={onExportToExcel}/>
        </Dropdown>
      </div>
    </div>
  );
};

export default memo(ProjectTransactionsMenu);
