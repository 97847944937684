import React, {memo, useEffect} from "react";
import Input from "../../../Components/Input";
import Select from "../../../Components/Select";
import Textarea from "../../../Components/Textarea";
import APIService from "../../../services/api";


const BillForm = (
  {
    date,
    number,
    counterparty,
    tim_counterparty,
    details,
    documentsDate,
    errors,
    isCashless,
    onBillUpdate
  }
) => {
  const api = new APIService();

  useEffect(() => {
    !isCashless && !number && onBillUpdate('number', 'Б.Н.');
  }, [isCashless]);

  return (
    <>
      <div className='row'>
        <div className="col mb-2"><b>Рахунок</b></div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-4">
          <Select
            isAsync
            isClearable
            isCreatable
            isSearchable
            defaultValue={counterparty}
            getOptions={api.getCounterparties}
            placeholder='Постачальник'
            error={errors && errors['counterparty']}
            onChange={option => onBillUpdate('counterparty', option)}
          />
        </div>
      </div>
      {isCashless && (
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-4">
            <Select
              isAsync
              isClearable
              isSearchable
              defaultValue={tim_counterparty}
              getOptions={api.getTIMCounterparties}
              placeholder='Покупець'
              error={errors && errors['tim_counterparty']}
              onChange={option => onBillUpdate('tim_counterparty', option)}
            />
          </div>
        </div>
      )}
      <div className="row d-flex justify-content-center">
        <div className={`col-12 col-lg-2`}>
          <Input
            value={number}
            type={'text'}
            placeholder="Номер рахунку"
            error={errors && errors['number']}
            onChange={e => onBillUpdate('number', e.target.value)}
          />
        </div>
        <div className={`col-12 col-lg-2`}>
          <Input
            value={date}
            type={'date'}
            placeholder="Дата рахунку"
            error={errors && errors['date']}
            onChange={e => onBillUpdate('date', e.target.value)}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className={`col-12 col-lg-4`}>
          <Textarea
            value={details || ''}
            type={'text'}
            placeholder='Реквізити'
            error={errors && errors['details']}
            onChange={e => onBillUpdate('details', e.target.value)}
          />
        </div>
      </div>
      {isCashless && (
        <div className="row d-flex justify-content-center">
          <div className={`col-12 col-lg-4`}>
            <Input
              value={documentsDate}
              type={'date'}
              placeholder="Планова дата отримання акту/накладної"
              error={errors && errors['documents_date']}
              onChange={e => onBillUpdate('documents_date', e.target.value || '')}
            />
          </div>
        </div>
      )}
    </>
  )
}

const propsAreEqual = (prev, next) => {
  return prev.date === next.date &&
    prev.number === next.number &&
    prev.counterparty === next.counterparty &&
    prev.tim_counterparty === next.tim_counterparty &&
    prev.details === next.details &&
    prev.isCashless === next.isCashless &&
    prev.errors === next.errors &&
    prev.documentsDate === next.documentsDate;
};

export default memo(BillForm, propsAreEqual);