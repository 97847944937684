import React, {useState} from "react";

import Form from "../../Components/Form";
import Select from "../../Components/Select";
import APIService from "../../services/api";
import Input from "../../Components/Input";

const CloseTransactionModalWindow = ({timCounterpartyProp, onSubmit}) => {
  const [timCounterparty, setTimCounterparty] = useState(
    () => timCounterpartyProp ? timCounterpartyProp.value : null
  );
  const [paymentDate, setPaymentDate] = useState('');

  const api = new APIService();

  return (
    <Form
      headerText='Закриття транзакції'
      submitButtonText='Закрити'
      onSubmit={() => onSubmit(timCounterparty || null, paymentDate)}
    >
      <div className="row">
        <div className="col">
          <Select
            isAsync
            isClearable
            isSearchable
            placeholder='Покупець'
            defaultValue={timCounterpartyProp}
            getOptions={api.getTIMCounterparties}
            onChange={option => setTimCounterparty(option ? option.value : null)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Input
            autoFocus
            value={paymentDate}
            type={'date'}
            placeholder="Дата оплати рахунку"
            onChange={e => setPaymentDate(e.target.value)}
          />
        </div>
      </div>
    </Form>
  )
}

export default CloseTransactionModalWindow;