import React, {useEffect} from 'react';
import Select from "../../Components/Select";
import APIService from "../../services/api";
import {IsAccountantContext} from "../../context";

const Filters = ({onFiltersChange}) => {
  const [manager, setManager] = React.useState(null);
  const [counterparty, setCounterparty] = React.useState(null);
  const [timCounterparty, setTimCounterparty] = React.useState(null);

  useEffect(() => {
    onFiltersChange(manager, counterparty, timCounterparty);
  }, [manager, counterparty, timCounterparty]);

  const api = new APIService();

  const isAccountant = React.useContext(IsAccountantContext);

  return (
    <>
      {isAccountant && (<div className="col-3">
        <Select
          isSearchable
          isClearable
          getOptions={api.getUsers}
          placeholder='Користувач'
          onChange={option => setManager(option ? option.value : null)}
        />
      </div>)}
      <div className="col-3">
        <Select
          isSearchable
          isClearable
          isAsync
          getOptions={api.getCounterparties}
          placeholder='Постачальник'
          onChange={option => setCounterparty(option ? option.value : null)}
        />
      </div>
      <div className="col-3">
        <Select
          isSearchable
          isClearable
          isAsync
          getOptions={api.getTIMCounterparties}
          placeholder='Покупець'
          onChange={option => setTimCounterparty(option ? option.value : null)}
        />
      </div>
    </>
  )
}

export default Filters;
