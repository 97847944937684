import React from "react";

import Form from "../../Components/Form";
const BillDocModal = ({bill, onSubmit}) => {
  if (!bill) return null;
  return (
    <Form
      headerText='Акт/накладна отримана?'
      submitButtonText='Підтвердити'
      onSubmit={onSubmit}
    >
      <div className='pl-1'>
        <h3># {bill.number} від {bill.date}</h3>
        <p><b>Постачальник:</b> {bill.counterparty}</p>
        <p><b>Покупець:</b> {bill.tim_counterparty}</p>
        <p><b>Сума:</b> {bill.amount}</p>
      </div>
    </Form>
  )
}

export default BillDocModal;
