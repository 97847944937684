import React, {memo} from 'react';
import {IsAccountantContext} from "../../../context";

const BillsListTableHead = () => {
  const isAccountant = React.useContext(IsAccountantContext);
  return (
    <tr>
      <td>#</td>
      <td>Сума</td>
      <td>Дата</td>
      {isAccountant && <td>Користувач</td>}
      <td>Постачальник</td>
      <td>Покупець</td>
      <td style={{width: '100px'}}>Оплачено</td>
      <td style={{width: '100px'}}>Док. повернуто</td>
    </tr>
  );
};

export default memo(BillsListTableHead, () => true);