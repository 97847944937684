import React, {useEffect, useState, useContext, useMemo, useCallback} from "react";

import {useNavigate} from "react-router-dom";

import Button from "../../../Components/Button";
import TransactionForm from './TransactionForm';
import BillForm from './BillForm';
import Attachments from '../Attachments';
import TransactionItemFormRow from './TransactionItemFormRow';
import TotalAmountBadge from "./TotalAmountBadge";
import {useSnackbar} from "../../../hooks";
import {ProjectContext} from "../../../context";
import APIService from "../../../services/api";

import './TransactionForm.css';


const TransactionFormSet = ({
                              transaction,
                              transactionItems,
                              bill,
                              errors,
                              isOutcome,
                              showBillForm,
                              showPayerField,
                              setTransaction,
                              setTransactionItems,
                              setBill,
                              onFormSubmit,
                            }) => {
  const [needSave, setNeedSave] = useState(false);
  const [payer, setPayer] = useState(null);

  const navigate = useNavigate();
  const openSnackbar = useSnackbar();
  const {project, getProject} = useContext(ProjectContext);

  const api = new APIService();

  useEffect(() => {
    transactionItems && !transactionItems.length && addTransactionItem();
  }, [transactionItems]);

  useEffect(() => {
    setNeedSave(true);
  }, [transaction.date, transaction.comment, transaction.description, transaction.tax, transaction.payer]);

  useEffect(() => {
    if (!transaction.payer) {
      return setPayer(null);
    }
    api.getUser(transaction.payer.value)
      .then(res => setPayer(res))
      .catch(err => openSnackbar(err.message));
  }, [transaction.payer]);

  const updateTransaction = (field, value) => {
    setTransaction(state => ({...state, [field]: value}));
  };

  const updateBill = (field, value) => {
    setBill(state => {
      if (!state) return {[field]: value};
      return {...state, [field]: value};
    });
    setNeedSave(true);
  };

  const deleteTransaction = useCallback(async () => {
    if (!window.confirm('Підтвердіть видалення транзакції.')) {
      return;
    }
    try {
      await api.deleteTransaction(transaction.id);
      navigate(`/project/${transaction.project}/transactions`);
    } catch (e) {
      openSnackbar(e.message);
    }
  }, [transaction.id]);

  const addTransactionItem = useCallback(async () => {
    await setTransactionItems(items => [...items, {
      id: `new_${items.length}`,
      goods: null,
      name: '',
      amount: '',
      selling_amount: '',
      quantity: '',
      extra_charge_percentage: '0',
      extra_charge_amount: '0',
      total_amount: '0',
      selling_total_amount: '0',
    }]);
    // if (transactionItems.length > 0) {
    //     window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
    // }
  }, [transactionItems]);

  const updateTransactionItem = (itemId, column, value) => {
    setTransactionItems(items => {
      return items.map(ti => (ti.id === itemId ? {...ti, [column]: value} : ti));
    });

    if (column !== 'total_amount') {
      setNeedSave(true);
    }
  }

  const deleteTransactionItem = async (rowId) => {
    if (!window.confirm('Підтвердіть видалення рядку.')) {
      return;
    }
    if (parseInt(rowId)) {
      try {
        await api.deleteTransactionItem(rowId);
      } catch (e) {
        return openSnackbar(e.message);
      }
    }
    setTransactionItems(items => items.filter(ti => ti.id !== rowId));
  }

  const submitForm = async () => {
    await onFormSubmit() && setNeedSave(false);
    await getProject(project.id);
  }

  const totalAmount = useMemo(() => {
    const totalAmountReducer = (currentValue, transactionItem) => currentValue + parseFloat(transactionItem.total_amount);
    if (!transactionItems) return 0;
    return transactionItems.reduce(totalAmountReducer, 0).toFixed(2);
  }, [transactionItems]);

  const sellingTotalAmount = useMemo(() => {
    const totalAmountReducer = (currentValue, transactionItem) => currentValue + parseFloat(transactionItem.selling_total_amount);
    if (!transactionItems) return 0;
    return transactionItems.reduce(totalAmountReducer, 0).toFixed(2);
  }, [transactionItems]);

  if (!transactionItems || !transaction) return null;

  return (
    <div className={`${isOutcome ? 'container-fluid' : 'container'} transaction-form p-0`}>
      <TransactionForm
        date={transaction.date}
        tax={transaction.tax}
        description={transaction.description}
        payer={transaction.payer}
        comment={transaction.comment}
        isOutcome={isOutcome}
        showPayerField={showPayerField}
        errors={errors}
        onTransactionUpdate={updateTransaction}
      />
      <hr/>
      {payer && isOutcome && (
        <>
          <BillForm
            date={bill && bill.date ? bill.date : Date.now().toString()}
            number={bill ? bill.number : ''}
            counterparty={bill ? bill.counterparty : null}
            tim_counterparty={bill ? bill.tim_counterparty : null}
            details={bill ? bill.details : ''}
            documentsDate={bill ? bill.documents_date : Date.now().toString()}
            errors={errors && errors.bill}
            isCashless={payer.settings.is_main_accountant}
            onBillUpdate={updateBill}
          />
          <hr/>
        </>
      )}
      <Attachments
        transactionId={transaction.id}
        attachmentItems={transaction.attachments}
      />
      {transactionItems && transactionItems.map(ti => (
        <TransactionItemFormRow
          key={`${ti.id}`}
          item={ti}
          isOutcome={isOutcome}
          isPotProject={project.is_pot}
          currency={project.currency}
          errors={errors && errors.items && (errors.items[ti.id] || null)}
          onRowUpdate={updateTransactionItem}
          onRowDelete={deleteTransactionItem}
        />
      ))}
      <div className="row d-flex justify-content-between my-3">
        <div className="col-12 col-lg-2">
          <Button
            variant='grey small'
            text='Додати рядок'
            onClick={addTransactionItem}
          />
        </div>
        <div className="col-12 col-lg-4 d-flex justify-content-end  mt-3 mt-lg-0">
          <TotalAmountBadge
            totalAmount={`Всього: ${totalAmount}`}
            sellingTotalAmount={isOutcome ? `Продаж: ${sellingTotalAmount}` : null}
            diff={isOutcome && sellingTotalAmount !== '0.00' ? `Різниця: ${(sellingTotalAmount - totalAmount).toFixed(2)}` : null}
            currency={project.currency}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className='col-6 col-lg-2 d-flex justify-content-start'>
          <Button
            variant='danger'
            text='Видалити'
            onClick={deleteTransaction}
          />
        </div>
        <div className='col-6 col-lg-2 justify-content-end'>
          {needSave && (
            <Button
              text='Зберегти'
              onClick={submitForm}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TransactionFormSet;