import React, {memo, useContext, useMemo} from 'react';
import {ProjectContext} from "../../context";
import APIService from "../../services/api";


const ProjectFavouriteButton = () => {
  const {project, getProject} = useContext(ProjectContext);

  const isFavourite = useMemo(() => project?.is_favourite, [project]);

  const toggleProjectFavourite = async () => {
    const api = new APIService();

    const toggleProjectFavouriteFunc = isFavourite ? api.removeProjectFromFavourite : api.addProjectToFavourite;

    toggleProjectFavouriteFunc(project.id)
      .then(() => getProject(project.id));
  };

  if (!project) return null;

  return (
    <button
      className={`favourite-project ${isFavourite && 'favourite-project--active'}`}
      title={isFavourite ? 'Прибрати з обраного' : 'Додати в обране'}
      onClick={toggleProjectFavourite}
    >
      {`\u2605`}
    </button>
  );
}

export default memo(ProjectFavouriteButton, () => true);
