import React from 'react';

import {useNavigate} from "react-router-dom";

import Table from "../../../Components/Table";
import BillsListTableHead from './BillsListTableHead';
import BillsListTableRow from './BillsListTableRow';
import BillsListEmptyTable from './BillsListEmptyTable';

import './BillsListTable.css';


const BillsListTable = ({bills, onBillSelect}) => {
  return (
    <Table
      rows={bills}
      renderHead={() => <BillsListTableHead/>}
      renderRow={bill => (
        <BillsListTableRow
          key={bill.id}
          bill={bill}
          onBillSelect={() => onBillSelect(bill)}
        />
      )}
      renderEmpty={() => <BillsListEmptyTable/>}
    />
  );
};

export default BillsListTable;