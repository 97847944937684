import React from 'react';

import BillsListTable from "./BillsListTable";
import BillDocModal from "./BillDocModal";
import Filters from "./Filters";
import APIService from "../../services/api";
import {IsAccountantContext, UserContext} from "../../context";
import {useNavigate} from "react-router-dom";
import ModalWindow from "../../Components/ModalWindow";

const ProjectBillsList = () => {
  const [bills, setBills] = React.useState(null);
  const [selectedBill, setSelectedBill] = React.useState(null);

  const api = new APIService();

  const {user} = React.useContext(UserContext);

  const navigate = useNavigate();

  const isAccountant = React.useMemo(() => user?.roles.is_accountant, [user]);

  const billIsSelected = React.useMemo(() => selectedBill !== null, [selectedBill]);

  React.useEffect(() => {
    getBills();
  }, []);

  const getBills = (manager = null, counterparty = null, timCounterparty = null) => {
    api.getBills(manager, counterparty, timCounterparty)
      .then((data) => setBills(data));
  };

  const onBillSelect = (bill) => {
    if (isAccountant) {
      setSelectedBill(bill);
    } else {
      goToTransaction(bill.transaction);
    }
  };

  const markDocsAsReceived = () => {
    if (!selectedBill) return;
    api.markBillDocsAsReceived(selectedBill.id)
      .then(bill => setBills(bills => bills.map(b => b.id === bill.id ? bill : b)))
      .then(() => setSelectedBill(null))
      .catch(() => setSelectedBill(null));
  }

  const goToTransaction = (transaction) => navigate(`/project/${transaction.project_id}/transaction/${transaction.id}`);

  if (!bills) return null;

  return (
    <IsAccountantContext.Provider value={isAccountant}>
      <ModalWindow
        showModal={billIsSelected}
        toggleModal={() => setSelectedBill(null)}
      >
        <BillDocModal
          bill={selectedBill}
          onSubmit={markDocsAsReceived}
        />
      </ModalWindow>
      <div className="row pt-4">
        <Filters
          onFiltersChange={getBills}
        />
      </div>
      <div className='row'>
        <div className="col">
          <BillsListTable
            bills={bills}
            onBillSelect={onBillSelect}
          />
        </div>
      </div>
    </ IsAccountantContext.Provider>
  );
};

export default ProjectBillsList;
