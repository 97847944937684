import {memo, useState} from "react";

import ErrorText from "../ErrorText";
import './Input.css';

const Input = ({value, type, placeholder, error, isDisabled, autoFocus = false, onFocus, onChange}) => {
    const [isFocused, setIsFocused] = useState(false);

    const onInputFocus = (e) => {
        setIsFocused(true);
        onFocus && onFocus(e);
        e.target.select();
    }

    return (
        <div className='d-flex flex-column justify-content-start'>
            <div className='input-wrapper'>
                <input
                    className={`input ${error ? 'input--error' : ''}`}
                    value={value}
                    type={type}
                    disabled={isDisabled}
                    onChange={onChange}
                    autoFocus={autoFocus}
                    onFocus={onInputFocus}
                    onBlur={e => setIsFocused(false)}
                />
                <span className={`floating-label ${value || isFocused ? 'small' : 'big'}`}>{placeholder}</span>
            </div>
            {error && <ErrorText text={error}/>}
        </div>
    )
}

const propsAreEqual = (prevProps, nextProps) => {
    return prevProps.value === nextProps.value &&
        prevProps.error === nextProps.error &&
        prevProps.isDisabled === nextProps.isDisabled;
};

export default memo(Input, propsAreEqual);