import React from 'react';
import {IsAccountantContext} from "../../../context";

const BillsListEmptyTable = () => {
  const isAccountant = React.useContext(IsAccountantContext);
  return (
    <tr>
      <td colSpan={isAccountant ? 8 : 7}>
        <b>Поточні рахунки відсутні.</b>
      </td>
    </tr>
  );
};

export default BillsListEmptyTable;