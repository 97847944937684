import React, {memo} from 'react';
import {IsAccountantContext} from "../../../context";

const BillsListTableRow = ({bill, onBillSelect}) => {
  const isAccountant = React.useContext(IsAccountantContext);
  return (
    <>
      <tr onClick={onBillSelect} className={bill.documents_is_overdue ? 'bill-row-danger' : ''}>
        <td>{bill.id}</td>
        <td><b>{bill.amount}</b></td>
        <td>{bill.date}</td>
        {isAccountant && <td>{bill.author}</td>}
        <td>{bill.counterparty}</td>
        <td>{bill.tim_counterparty}</td>
        <td>{bill.is_payed ?
          <span className='confirm-badge'>&#10003;</span> :
          <span className='not-confirm-badge'>&#10007;</span>}
        </td>
        <td title={bill.documents_date || ''}>{bill.documents_received ?
          <span className='confirm-badge'>&#10003;</span> :
          <span className='not-confirm-badge'>&#10007;</span>}
        </td>
      </tr>
    </>
  );
};

export default memo(BillsListTableRow);